import { apiFetch, validatedApiFetch } from '@/utils/api/utils'
import { z } from 'zod'
import { type Announcement, AnnouncementSchema } from '@/utils/types/announcement'

const AnnouncementListSchema = z.array(AnnouncementSchema)
export const listAnnouncements = (spaceUuid: string) => validatedApiFetch({
  url: `/api/v1/spaces/${spaceUuid}/announcements.json`,
  schema: AnnouncementListSchema,
})

export const getAnnouncement = (announcementId: Announcement['id']) => validatedApiFetch({
  url: `/api/v1/announcements/${announcementId}.json`,
  schema: AnnouncementSchema,
})

export const getDailyAnnouncements = (spaceUuid: string, userId: number) => {
  const startDate = new Date()
  startDate.setUTCHours(0, 0, 0, 0)

  const endDate = new Date()
  endDate.setUTCHours(23, 59, 59, 999)

  return apiFetch({
    url: `/api/v1/spaces/${spaceUuid}/announcements.json`,
    options: {
      method: 'GET',
      params: {
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        for_user: userId,
      },
    },
  })
}

export const destroyAnnouncement = (announcementId: number) =>
  apiFetch({
    url: `/api/v1/announcements/${announcementId}.json`,
    options: {
      method: 'DELETE',
    },
  })

export type EditedAnnouncement = {
  id?: number
  start_datetime: string
  end_datetime: string
  user_group_ids?: Array<number>
  title: string
  message: string
  user_groups?: Array<{ id: number }>
  communication_start_datetime: string
  notification_enabled: boolean
  mycomeen_enabled: boolean
  send_after_initial_batch: boolean
  favorite_building_id: number | null
  favorite_floor_id: number | null
  favorite_area_id: number | null
  presence_building_id: number | null
  presence_floor_id: number | null
  presence_area_id: number | null
}

const makeAnnouncementPayload = (announcement: EditedAnnouncement) => {
  let start_datetime = new Date(announcement.start_datetime)
  start_datetime.setUTCHours(0, 0, 0, 0)

  let end_datetime = new Date(announcement.end_datetime)
  end_datetime.setUTCHours(23, 59, 59, 999)

  let user_group_ids: Array<number> = []

  if (!announcement.user_group_ids && announcement.user_groups) {
    user_group_ids = announcement.user_groups.map((user_group) => user_group.id)
  } else if (announcement.user_group_ids) {
    user_group_ids = announcement.user_group_ids
  }

  return {
    announcement: {
      // Event details
      title: announcement.title,
      message: announcement.message,
      start_datetime,
      end_datetime,
      // Announcement options
      communication_start_datetime: announcement.communication_start_datetime,
      notification_enabled: announcement.notification_enabled,
      mycomeen_enabled: announcement.mycomeen_enabled,
      send_after_initial_batch: announcement.send_after_initial_batch,
      // Targeting
      // User group filter
      user_group_ids,
      // Favorite location filter
      favorite_building_id: announcement.favorite_building_id,
      favorite_floor_id: announcement.favorite_floor_id,
      favorite_area_id: announcement.favorite_area_id,
      // Presence location filter
      presence_building_id: announcement.presence_building_id,
      presence_floor_id: announcement.presence_floor_id,
      presence_area_id: announcement.presence_area_id,
    },
  }
}

export const createAnnouncement = (spaceUuid: string, announcement: EditedAnnouncement) => apiFetch({
  url: `/api/v1/spaces/${spaceUuid}/announcements.json`,
  options: {
    method: 'POST',
    body: makeAnnouncementPayload(announcement),
  },
})

export const updateAnnouncement = (announcement: EditedAnnouncement) => apiFetch({
  url: `/api/v1/announcements/${announcement.id}.json`,
  options: {
    method: 'PUT',
    body: makeAnnouncementPayload(announcement),
  },
})

export const saveAnnouncement = (spaceUuid: string, announcement: EditedAnnouncement) => {
  if (announcement.id) {
    return updateAnnouncement(announcement)
  }

  return createAnnouncement(spaceUuid, announcement)
}

type GetAffectedUsersCountResponse = {
  affected: {
    count: number
  }
}
export const getAffectedUsersCount = (spaceUuid: string, announcement: EditedAnnouncement) => {
  const payload = makeAnnouncementPayload(announcement)

  return apiFetch<GetAffectedUsersCountResponse>({
    url: `/api/v1/spaces/${spaceUuid}/announcements/affected.json`,
    options: {
      params: {
        'announcement[start_datetime]': payload.announcement.start_datetime,
        'announcement[end_datetime]': payload.announcement.end_datetime,
        'announcement[user_group_ids][]': payload.announcement.user_group_ids,
        'announcement[presence_building_id]': payload.announcement.presence_building_id,
        'announcement[presence_floor_id]': payload.announcement.presence_floor_id,
        'announcement[presence_area_id]': payload.announcement.presence_area_id,
        'announcement[favorite_building_id]': payload.announcement.favorite_building_id,
        'announcement[favorite_floor_id]': payload.announcement.favorite_floor_id,
        'announcement[favorite_area_id]': payload.announcement.favorite_area_id,
      },
    },
  })
}