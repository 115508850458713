import { z } from 'zod'


export const AnnouncementSchema = z.object({
  id: z.number(),
  space: z.object({
    id: z.number(),
    name: z.string(),
    subdomain: z.string(),
    created_at: z.string(),
    updated_at: z.string(),
    uuid: z.string(),
    mapwize_key: z.string(),
    provider_mask: z.number(),
    legacy_mode: z.boolean(),
    discarded_at: z.null(),
    service_account_configuration: z.object({
      google: z.object({ email: z.string() }),
    }),
    logins_mask: z.number(),
    weeks_in_advance: z.number(),
    enable_recurring_presences: z.boolean(),
    enable_favorite_location: z.boolean(),
    enable_notifications_channel_choice: z.boolean(),
    provider_organisation_id: z.string(),
    enable_map_booking: z.boolean(),
    onboarding_completed_at: z.string(),
    microsoft_provider_id: z.string(),
  }),
  title: z.string(),
  message: z.string(),
  start_datetime: z.string(),
  end_datetime: z.string(),
  communication_start_datetime: z.string(),
  send_after_initial_batch: z.boolean(),
  enabled: z.boolean(),
  presence_building_id: z.number().nullable(),
  presence_floor_id: z.number().nullable(),
  presence_area_id: z.number().nullable(),
  favorite_building_id: z.number().nullable(),
  favorite_floor_id: z.number().nullable(),
  favorite_area_id: z.number().nullable(),
  notification_enabled: z.boolean(),
  mycomeen_enabled: z.boolean(),
  user_groups: z.array(z.object({
    id: z.number(),
  })),
  announcer: z.object({
    id: z.number(),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string(),
    provider_id: z.string(),
  }),
  url: z.string(),
})
export type Announcement = z.infer<typeof AnnouncementSchema>
